<template>
  <div>
    <b-row
      class="mt-2"
    >
      <template
        v-for="post in paginatedPosts"
      >
        <b-col
          :key="post.id"
          lg="6"
          md="12"
          class="d-flex align-items-center justify-content-center"
        >
          <div class="posts-box">

            <!-- Background Image -->
            <b-img-lazy
              :src="post.image"
              alt="Background Image"
              style="height: 400px; width: 500px; object-fit: cover;"
            />

            <!-- Over Text -->
            <div class="posts-box-content">

              <h2>
                <b-link :to="{ name: 'onePost', params: { idPost: post.id } }">
                  {{ post.title }}
                </b-link>
              </h2>
            </div>
          </div>
        </b-col>
      </template>
    </b-row>
    <b-pagination
      v-model="currentPage"
      :total-rows="posts.length"
      :per-page="perPage"
      class="d-flex align-items-center justify-content-center"
    />
  </div>
</template>

<script>
import moment from 'moment'
import {
  BImgLazy,
  BRow,
  BCol,
  BLink,
  BPagination,
} from 'bootstrap-vue'

export default {
  name: 'AllPosts',
  components: {
    BImgLazy,
    BRow,
    BCol,
    BPagination,
    BLink,
  },
  props: {
    posts: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 4,
    }
  },
  computed: {
    paginatedPosts() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.posts.slice(start, end)
    },
  },
  methods: {
    formatDate(date) {
      moment.locale('es')
      const postDate = moment(date, 'YYYY-MM-DD').format('LL')
      return postDate
    },
  },
}
</script>
