<template>
  <div class="posts">

    <!-- Banner -->
    <banner
      :image="data.bannerImage"
      :video="data.bannerVideo"
      :title="$t('posts.bannerTitle')"
      height="600"
    />

    <div class="container-lg mt-5">
      <b-row>

        <!-- All Posts -->
        <b-col
          lg="8"
          md="12"
        >
          <all-posts
            :posts="filteredPosts"
          />
        </b-col>

        <!-- Last posts -->
        <b-col
          lg="4"
          md="12"
        >

          <last-posts />

          <files
            :data="data.posts"
            @filter-posts="filterPosts"
          />

          <social-media />

        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import Banner from 'Components/Common/Banner.vue'
import LastPosts from 'Components/Posts/LastPosts.vue'
import Files from 'Components/Posts/Files.vue'
import SocialMedia from 'Components/Posts/SocialMedia.vue'
import AllPosts from 'Components/Posts/AllPosts.vue'

import MyData from 'Data/posts.json'

import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'Posts',
  components: {
    Banner,
    BRow,
    BCol,
    LastPosts,
    Files,
    SocialMedia,
    AllPosts,
  },
  data() {
    return {
      data: MyData.data,
      filteredPosts: MyData.data.posts,
    }
  },
  methods: {
    filterPosts(filterDate) {
      if (filterDate === null) {
        this.filteredPosts = MyData.data.posts
      } else {
        this.filteredPosts = []
        this.data.posts.forEach(post => {
          const postDate = moment(post.date, 'YYYY-MM-DD').format('YYYY-MM')
          if (filterDate === postDate) {
            this.filteredPosts.push(post)
          }
        })
      }
    },
  },
}
</script>

<style scoped>

  h1 {
    color: black !important;
  }

  .posts {
    background-color: #f4f7f9;
  }

  .posts-container {
    padding-left: 550px;
    padding-right: 550px;
    padding-top: 50px;
    top: auto;
  }

</style>
