<template>
  <div class="mt-3">

    <!-- Files -->
    <h1>
      {{ $t('posts.files') }}
    </h1>

    <!-- Select -->
    <b-form-select
      v-model="selected"
      :options="options"
      style="border-radius: 20px"
    />
  </div>
</template>

<script>

import moment from 'moment'
import { BFormSelect } from 'bootstrap-vue'

export default {
  name: 'Files',
  components: {
    BFormSelect,
  },
  props: {
    data: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Elegir el mes' },
      ],
    }
  },
  watch: {
    selected() {
      this.$emit('filter-posts', this.selected)
    },
  },
  mounted() {
    this.setMomentLocale()
    this.setOptions()
  },
  methods: {
    setMomentLocale() {
      moment.locale('es')
    },
    setOptions() {
      this.data.forEach(element => {
        const date = moment(element.date, 'YYYY-MM-DD').format('YYYY-MM')
        let option = moment(element.date, 'YYYY-MM-DD').format('MMMM YYYY')
        option = option.charAt(0).toUpperCase() + option.slice(1)
        const newOption = { value: date, text: option }

        // Verificar si el elemento ya existe en el array
        const existingIndex = this.options.findIndex(item => item.text === option)

        // Agregar el elemento solo si no existe en el array
        if (existingIndex === -1) {
          this.options.push(newOption)
        }
      })
    },
  },
}
</script>

<style scoped>

  h1 {
    color: black !important;
    font-size: 18px !important;
  }

</style>
